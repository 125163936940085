import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "hooks/useQuery";
import { getUserInfoApi } from "@/network/user/userApi";
import { userQueryKeys } from "@/stores/react-query/userQueryKeys";
import type { UserResponse } from "@/types/user/user.type";

const useUserInfoBaseQuery = (options?: UseQueryOptions<UserResponse>) => {
  const {
    data,
    isFetching: userInfoLoading,
    error: userInfoError
  } = useQuery(
    userQueryKeys.userInfo(),
    () => getUserInfoApi<UserResponse>(),
    options
  );

  return {
    userInfo: data || ({} as UserResponse),
    userInfoLoading,
    userInfoError
  };
};

export default useUserInfoBaseQuery;
