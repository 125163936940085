import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "hooks/useQuery";
import { getApplessBikeInfo } from "@/network/bike/bikeApi";
import { bikeQueryKeys } from "@/stores/react-query/bikeQueryKeys";
import type { BikeGetParams, BikeInfoResponse } from "@/types/bike/bike.type";

const useBikeInfoBaseQuery = (
  params: BikeGetParams,
  options?: UseQueryOptions<BikeInfoResponse>
) => {
  const {
    data,
    isFetching: bikeInfoLoading,
    error: bikeInfoError
  } = useQuery(
    bikeQueryKeys.bikeInfo(params.sn),
    () => getApplessBikeInfo<BikeInfoResponse>(params),
    options
  );

  return {
    bikeInfo: data || ({} as BikeInfoResponse),
    bikeInfoLoading,
    bikeInfoError
  };
};

export default useBikeInfoBaseQuery;
